import 'normalize.css';
import './App.css';
import { Header } from './components/header/Header';
import { Main } from './components/main/Main';
import { Footer } from './components/footer/footer';
import { useEffect, useState } from 'react';

function App() {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch('data.json', {
      mode: 'no-cors',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true
    }).then(response =>
      response.json().then(data => setData(data)
      )
    )
  }, [])

  return (
    <div className="app">
      <Header data={data} />
      <Main data={data} />
      <Footer footer={data?.footer} />
    </div>
  );
}

export default App;
