import { Rating } from "@mui/material"
import ZeroIcon from '../../../assets/imgs/ps_icons/0.svg'
import OneIcon from '../../../assets/imgs/ps_icons/1.svg'
import TwoIcon from '../../../assets/imgs/ps_icons/2.svg'
import ThreeIcon from '../../../assets/imgs/ps_icons/3.svg'

export const CasinoCard = ({ cc }) => {
  return (
    <div className="сardWrapper">
      <div className="card">
        <div className="left">
          {cc?.heading_tag && <div className="tag">
            {cc?.heading_tag}
          </div>}
          <div className="logo">
            <img src={cc?.casino_logo || 'assets/imgs/casino_default.png'} alt="" />
          </div>
          <div className="ccRatings">
            <div className="ratingNumbers">
              <div className="ccRating">
                {cc?.rating} <span>/10</span>
              </div>
              <div className="ccRatingText">
                {cc?.rating_text}
              </div>
            </div>
            <div className="stars">
              <Rating name="read-only" value={cc?.rating / 2} readOnly precision={0.5} size="small" />
              <div className="ccTestingResults">
                {cc?.testing_results}
              </div>
            </div>
          </div>
          <div className="ccPaymentMethods">
            <div className="ccPaymentSystems">
              {cc?.payment_systems.length
                ? cc?.payment_systems?.map((key, i) => <img src={key} key={`${key}+${i}`} />)
                : <>
                  <img src={ZeroIcon} />
                  <img src={OneIcon} />
                  <img src={TwoIcon} />
                  <img src={ThreeIcon} />

                </>}
            </div>
            <a href={cc?.plus_more_link} className="ccMore">
              {cc?.plus_more}
            </a>
          </div>
        </div>
        <div className="right">
          <div className="ccWelcomeBonusTitle">
            {cc?.welcome_bonus_title}
          </div>
          <div className="ccBonusDescr">
            <div className="cdFirst">{cc?.bonus_first_text}</div>
            <div className="cdSecond">{cc?.bonus_second_text}</div>
            {cc?.bonus_third_text && <div className="cdThird">{cc?.bonus_third_text}</div>}
          </div>
          <a href={cc?.button_link} className="ccBtn">
            {cc?.button_text}
          </a>
          {cc?.text_under_button && <div className="ccTextUnderButton">
            {cc?.text_under_button}
          </div>}
        </div>
      </div>
    </div>
  )
}