export const Footer = ({ footer }) => {
  return (
    <div className="footerWrapper">
      <div className="footerText">{footer?.footer_text}</div>
      <div className="footerPartners">
        {footer?.partner_logos.length
          && footer?.partner_logos.map((logo, i) => <img src={logo} alt="" key={`${logo}+${i}`} />)}
      </div>
    </div>
  )
}