import { CasinoCard } from "./casino-cards/casino-card"
import { FirstCard } from "./casino-cards/first-card"
import { TextContent } from "./content/content"

export const Main = ({ data }) => {

  return (
    <main className='main'>
      <FirstCard fc={data?.first_place_card} />
      {data?.casino_cards.map((cc, i) =>
        <CasinoCard cc={cc} key={i} />)}
      <div className="content">
        {data?.content.map((content, i) =>
          <TextContent content={content} key={i} />)}
      </div>
    </main>
  )
}