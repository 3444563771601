const options = {
  year: "numeric",
  month: "long",
  day: "numeric",
};
const date = new Date();
const currentDate = date.toLocaleString('default', options);

export const CurrentDate = ({ actuality }) => {
  return (
    <p className="currentDate">
      {actuality}: {currentDate}
    </p>
  )
}