export const TextContent = ({ content }) => {
  return (
    <div className="contentWrapper">
      <div className="contentImg">
        <img src={content?.content_img} alt="" />
      </div>
      <div className="contentTitle">{content?.content_title}</div>
      <div className="contentText">{content?.content_text}</div>
    </div>
  )
}