import { Rating } from "@mui/material"
import ZeroIcon from '../../../assets/imgs/ps_icons/0.svg'
import OneIcon from '../../../assets/imgs/ps_icons/1.svg'
import TwoIcon from '../../../assets/imgs/ps_icons/2.svg'
import ThreeIcon from '../../../assets/imgs/ps_icons/3.svg'

export const FirstCard = ({ fc }) => {
  return (
    <div className="firstCardWrapper">
      <div className="firstCard">
        <div className="fcLeft">
          <div className="fcTag">
            {fc?.heading_tag}
          </div>
          <div className="fcLogo">
            <img src={fc?.casino_logo || 'assets/imgs/casino_default.png'} alt="" />
          </div>
          <div className="fcRatings">
            <div className="ratingNumbers">
              <div className="rating">
                {fc?.rating} <span>/10</span>
              </div>
              <div className="ratingText">
                {fc?.rating_text}
              </div>
            </div>
            <div className="stars">
              <Rating name="read-only" value={fc?.rating / 2} readOnly precision={0.5} size="small" />
              <div className="testingResults">
                {fc?.testing_results}
              </div>
            </div>
          </div>
          <div className="paymentMethods">
            <div className="paymentSystems">
              {fc?.payment_systems.length
                ? fc?.payment_systems?.map((key, i) => <img src={key} key={`${key}+${i}`} />)
                : <>
                  <>
                    <img src={ZeroIcon} />
                    <img src={OneIcon} />
                    <img src={TwoIcon} />
                    <img src={ThreeIcon} />

                  </>
                </>}
            </div>
            <a href={fc?.plus_more_link} className="fcMore">
              {fc?.plus_more}
            </a>
          </div>
        </div>
        <div className="fcRight">
          <div className="welcomeBonusTitle">
            {fc?.welcome_bonus_title}
          </div>
          <div className="fcBonusDescr">
            <div className="dFirst">{fc?.bonus_first_text}</div>
            <div className="dSecond">{fc?.bonus_second_text}</div>
            <div className="dThird">{fc?.bonus_third_text}</div>
          </div>
          <a href={fc?.button_link} className="fcBtn">
            {fc?.button_text}
          </a>
          <div className="textUnderButton">
            {fc?.text_under_button}
          </div>
        </div>
      </div>
    </div>
  )
}