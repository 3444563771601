import { AdvantCard } from "./advantages/AdvantCard"
import { CurrentDate } from "./current-date/currentDate"

export const Header = ({ data }) => {
  return (
    <header className="header">
      <h1>
        <img src={data?.header.flag_url} alt="" />
        {data?.header.main_title}
      </h1>
      <div className="headerContentWrapper">
        <h2>
          {data?.header.second_title_first}
          <span className="h2Span"> {data?.header.second_title_second}</span>
        </h2>
        <div className="advantages-block">
          {data?.header?.advantages.map((key, i) => (
            <AdvantCard text={key} key={key} />
          ))}
        </div>
        <div className="currentDateBlock">
          <CurrentDate actuality={data?.header?.current_date} />
        </div>
      </div>
    </header>
  )
}