import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const AdvantCard = ({ text }) => {
  return (
    <div className='advantCard'>
      <TaskAltIcon className='goldElem' />
      <p>
        {text}
      </p>
    </div>
  )
}